import React from 'react'
import styled from 'styled-components'
import { customMedia } from '../components/common/customMedia'
import { Reveal, Animation } from 'react-genie'
import HeadShake from 'react-reveal/HeadShake'
import Pulse from 'react-reveal/Pulse'
import Bounce from 'react-reveal/Bounce'

import { Layout } from '../components/Layout'
import { SEO } from '../components/common/SEO'
import { Image } from '../components/common/Image'
import { SpBr } from '../components/common/SpBr'
import { PcBr } from '../components/common/PcBr'
import { SpOnly } from '../components/common/SpOnly'
import { PcOnly } from '../components/common/PcOnly'
import { Breadcrumb } from '../components/common/Breadcrumb'
import { Jumbotron } from '../components/common/Jumbotron'
import { Point } from '../components/common/Point'

const CoverImage = require('../images/generic_main.jpg')

const IndexPage = () => (
  <Layout>
    <SEO 
      title='ジェネリック医薬品について　| シンビ堂'
      description='意外に知らないジェネリック医薬品をシンビ堂では丁寧に説明しています。安いから効き目は？安全性は？何が違うの？といった疑問にお答えしています。'
      keywords={['ジェネリック医薬品','軽井沢','シンビ堂','調剤薬局','薬局']}
    />
    <h1>ジェネリック医薬品 軽井沢　薬局 調剤薬局 | シンビ堂</h1>

    <Container>
      <Jumbotron imageSrc={CoverImage} jaTitle={`ジェネリック医薬品って\nどんなもの？`} objectPosition={55} />
      <BreadcrumbWrapper>
        <Breadcrumb color='blue' current='ジェネリック医薬品ってどんなもの？' />
      </BreadcrumbWrapper>
      <Reveal delay={500} animation={Animation.FadeInUp}>
        <SubTitle>ジェネリック医薬品とは、新薬と同等の有効成分・効能があると<PcBr />厚生労働省から認められている安価なお薬です。</SubTitle>
      </Reveal>
      <Point color='pink' title='POINT1' subTitle={`新薬とジェネリック\n医薬品の違い`}>
        <Point1ItemContainer>
          <Point1Wrapper>
            <Reveal delay={1500} animation={Animation.FadeInLeft}>
              <HeadShake delay={1600}>
                <Point1ImageWrapper><Image path='generic_new@2x.png' /></Point1ImageWrapper>
              </HeadShake>
            </Reveal>
            <Reveal delay={2000} animation={Animation.FadeInUp}>
              <Point1Description>
                <div>（先発医薬品）</div>
                <div>新しく開発された医薬品で、先発医薬品とも呼ばれています。特許を出願してから20～25年間、開発メーカーが独占的に製造・販売することができます。</div>
              </Point1Description>
            </Reveal>
          </Point1Wrapper>
          <Point1Wrapper>
            <Reveal delay={2500} animation={Animation.FadeInRight}>
              <HeadShake delay={2600}>
                <Point1ImageWrapper><Image path='generic_generic@2x.png' /></Point1ImageWrapper>
              </HeadShake>
            </Reveal>
            <Reveal delay={3000} animation={Animation.FadeInUp}>
              <Point1Description>
                <div>（後発医薬品）</div>
                <div>新薬の特許期間が過ぎた後に、医薬品メーカーが同じ有効成分で製造・販売するお薬です。新薬と効き目や品質が同等でありながら、開発費が大幅に削減できるため、新薬に比べ安価で購入する事ができます。</div>
              </Point1Description>
            </Reveal>
          </Point1Wrapper>

        </Point1ItemContainer>
      </Point>

      <Point color='white' title='POINT2' subTitle={`効き目や安全性は\n大丈夫なの？`}>
        <Point2ImageContainer>
          <Reveal delay={1500} animation={Animation.FadeInLeft}>
            <HeadShake delay={1550}>
              <Point2ImageWrapper><Image path='generic_new@2x.png' /></Point2ImageWrapper>
            </HeadShake>
          </Reveal>
      
          <Reveal delay={2500} animation={Animation.FadeInUp}>
            <Point2EqualImageWrapper>
              <SpOnly><Image path='generic_equal_sp@2x.png' /></SpOnly>
              <PcOnly><Image path='generic_equal_pc@2x.png' /></PcOnly>
            </Point2EqualImageWrapper>
          </Reveal>

          <Reveal delay={2000} animation={Animation.FadeInRight}>
            <HeadShake delay={2050}>
              <Point2ImageWrapper><Image path='generic_generic@2x.png' /></Point2ImageWrapper>
            </HeadShake>
          </Reveal>
        </Point2ImageContainer>

        <Reveal delay={2500} animation={Animation.FadeInUp}>
          <Point2Description>
            厳しい試験に合格し、厚生労働大臣の承認を受け、国の基準、法律に基づいて<PcBr />
            製造・販売しておりますので、安心してご使用ください。
          </Point2Description>
        </Reveal>
      </Point>

      <Point color='pink' title='POINT3' subTitle={`どうして新薬と\n比べて価格が安いの？`}>
        <Reveal delay={2000} animation={Animation.FadeInUp}>
          <Point3Description1>ジェネリック医薬品は新薬と同一の有効成分を含み、効き目や安全性が同等であると、<PcBr />厚生労働省が承認したお薬です。</Point3Description1>
        </Reveal>
        <Point3ImageDescription>
          <Point3ImageWrapper>
            <Reveal delay={3000} animation={Animation.FadeIn}>
              <Image path='generic_point3@2x.png' />
            </Reveal>
            <Point3FeatureContainer>
              <Reveal delay={3300} animation={Animation.FadeIn}>
                <Point3Feature1>
                  <Pulse delay={3500}>
                    <div>新薬の開発期間</div>
                    <div>10〜15年</div>
                  </Pulse>
                </Point3Feature1>
              </Reveal>
              <Reveal delay={3700} animation={Animation.FadeIn}>
                <Point3Feature2>
                  <Pulse delay={3900}>
                    <div>新薬の開発費用</div>
                    <div>数百億円以上</div>
                  </Pulse>
                </Point3Feature2>
              </Reveal>
          </Point3FeatureContainer>
          </Point3ImageWrapper>
        </Point3ImageDescription>

        <Reveal delay={4000} animation={Animation.FadeIn}>
          <Point3Arrow />
        </Reveal>
        
        <Reveal delay={4500} animation={Animation.FadeIn}>
          <Point3Description2>ジェネリック医薬品では<SpBr />大幅に抑える事が出来る！</Point3Description2>
        </Reveal>          

        <Reveal delay={5000} animation={Animation.FadeIn}>
          <Point3Description3>
            新薬は、10～15年もの歳月と、数百億円以上といわれる費用をかけて開発されるので、<PcBr />
            その分価格も高くなります。これに比べてジェネリック医薬品の場合、<PcBr />
            既に有効性や安全性について先発医薬品で確認されていることから、<PcBr />
            開発期間やコストを大幅に抑えられるのです。<PcBr />
            そのため、ジェネリック医薬品の価格を安く設定することができます。
          </Point3Description3>
        </Reveal>
      </Point>

      <Point color='white' title='POINT4' subTitle={`使用するには\nどうしたらいいの？`}>
        <Reveal delay={2000} animation={Animation.FadeIn}>
          <Point4Description1>かかりつけの医師または薬剤師に相談してみましょう。</Point4Description1>
        </Reveal>

        <Point4ImageContainer>
          <Point4ImageWrapper>
            <Reveal delay={2500} animation={Animation.FadeIn}>
              <Bounce delay={2550}>
                <Image path='generic_doctor@2x.png' />
              </Bounce>
            </Reveal>
          </Point4ImageWrapper>
          <Point4ImageWrapper>
            <Reveal delay={3000} animation={Animation.FadeIn}>
              <Bounce delay={3050}>
                <Image path='generic_pharmacist@2x.png' />
              </Bounce>
            </Reveal>
          </Point4ImageWrapper>
        </Point4ImageContainer>
        <Reveal delay={3500} animation={Animation.FadeIn}>
          <Point4Description2>
            処方せんに記載された医薬品にジェネリック医薬品が発売されている場合は、<PcBr />
            患者さんの意思によって変更が可能です。診察時医師に、または処方せんを薬剤師に<PcBr />
            渡すときに「ジェネリック医薬品に変更出来ますか？」と聞いてみてください。
          </Point4Description2>
        </Reveal>
      </Point>

      <AskUsContainer>
        <AskUsLogoWrapper><Image path='zo_sm@2x.png' /></AskUsLogoWrapper>
        <AskUsTitle>シンビ堂でも<SpBr />ご相談いただけます</AskUsTitle>
        <AskUsDescription>
          現在使用している薬にジェネリック医薬品があるのか、<PcBr />
          変更した場合にどの程度安くなるのか等、店舗にて薬剤師へご気軽に<PcBr />
          ご相談いただければと思います。
        </AskUsDescription>
      </AskUsContainer>
    </Container>
  </Layout>
)

const Container = styled.div`
  ${customMedia.lessThan("medium")`
  `}
  ${customMedia.greaterThan("medium")`
  `}
`

const BreadcrumbWrapper = styled.div`
  ${customMedia.lessThan("medium")`
    margin: 15px 0 0 20px;
  `}
  ${customMedia.greaterThan("medium")`
    top: 590px;
    left: 100px;
    position: absolute;
  `}
`

const SubTitle = styled.div`
  ${customMedia.lessThan("medium")`
    font-size: 1.4rem;
    letter-spacing: 0.04em;
    padding: 20px;
  `}
  ${customMedia.greaterThan("medium")`
    font-size: 1.6rem;
    line-height: 30px;
    letter-spacing: 0.14em;
    margin: 40px 0 60px;
    text-align: center;
  `}

  color: #00559F;
`

const Point1ItemContainer = styled.div`
  ${customMedia.lessThan("medium")`
    margin-top: 20px;
  `}
  ${customMedia.greaterThan("medium")`
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    margin: 50px auto 60px;
    width: 800px;
  `}
`

const Point1Wrapper = styled.div`
  ${customMedia.lessThan("medium")`
  `}
  ${customMedia.greaterThan("medium")`
    width: 360px;
  `}
`

const Point1ImageWrapper = styled.div`
  ${customMedia.lessThan("medium")`
    width: 120px;
    margin: 0 auto;
  `}
  ${customMedia.greaterThan("medium")`
    width: 180px;
    margin: 0 auto;
  `}
`

const Point1Description = styled.div`
  ${customMedia.lessThan("medium")`
    font-size: 1.4rem;
    margin-bottom: 20px;

    div:first-child {
      font-weight: bold;
      letter-spacing: 0.22em;
      margin-top: 10px;
      text-align: center;
    }

    div:nth-child(2) {
      margin-top: 10px;
      letter-spacing: 0.04em;
    }
  `}
  ${customMedia.greaterThan("medium")`
    font-size: 1.6rem;

    div:first-child {
      margin-top: 10px;
      letter-spacing: 0.14em;
      text-align: center;
    }

    div:nth-child(2) {
      margin-top: 10px;
    }
  `}

  color: #fff;
`

const Point2ImageContainer = styled.div`
  ${customMedia.lessThan("medium")`
    margin: 30px 0 20px;
  `}
  ${customMedia.greaterThan("medium")`
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    -webkit-align-items: center;
    align-items: center;
    margin: 80px auto 0;
    width: 800px;
    width: 800px;
  `}
`

const Point2ImageWrapper = styled.div`
  ${customMedia.lessThan("medium")`
    margin: 0 auto;
    width: 120px;
  `}
  ${customMedia.greaterThan("medium")`
    margin: 0 auto;
    width: 180px;
  `}
`

const Point2EqualImageWrapper = styled.div`
  ${customMedia.lessThan("medium")`
    margin: 20px auto;
    width: 100px;
  `}
  ${customMedia.greaterThan("medium")`
    margin: 0 auto;
    width: 130px;
  `}
`

const Point2Description = styled.div`
  ${customMedia.lessThan("medium")`
    font-size: 1.4rem;
    letter-spacing: 0.04em;
    margin-bottom: 25px;
  `}
  ${customMedia.greaterThan("medium")`
    font-size: 1.6rem;
    line-height: 30px;
    letter-spacing: 0.14em;
    margin: 40px 0 55px;
    text-align: center;
  `}

  color: #00559F;
`

const Point3Description1 = styled.div`
  ${customMedia.lessThan("medium")`
    font-size: 1.4rem;
    letter-spacing: 0.04em;
    margin-top: 20px;
  `}
  ${customMedia.greaterThan("medium")`
    font-size: 1.6rem;
    margin-top: 30px;
    letter-spacing: 0.14em;
    text-align: center;
  `}

  color: #fff;
`

const Point3ImageDescription = styled.div`
  ${customMedia.lessThan("medium")`
  `}
  ${customMedia.greaterThan("medium")`
    margin-top: 40px;
  `}
`

const Point3ImageWrapper = styled.div`
  ${customMedia.lessThan("medium")`
    margin-top: 15px;
  `}
  ${customMedia.greaterThan("medium")`
    margin: 0 auto;
    width: 504px;
  `}
  position: relative;
`

const Point3FeatureContainer = styled.div`
  ${customMedia.lessThan("medium")`
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    margin-top: 20px;
  `}
`

const Point3Feature1 = styled.div`
  ${customMedia.lessThan("medium")`
    margin: 0 auto;
    height: 60px;
    width: 130px;
    overflow: hidden;

    div:first-child {
      font-size: 1.2rem;
      letter-spacing: 0.08em;
      margin-top: 10px;
    }

    div:nth-child(2) {
      font-size: 1.8rem;
      letter-spacing: 0.06em;
    }
  `}
  ${customMedia.greaterThan("medium")`
    height: 89px;
    position: absolute;
    left: 120px;
    top: 0;
    width: 173px;

    div:first-child {
      font-size: 1.4rem;
      letter-spacing: 0.08em;
      margin-top: 20px;
    }

    div:nth-child(2) {
      font-size: 2rem;    
      letter-spacing: 0.06em;
    }
  `}

  background-color: #fff;
  border-radius: 10px;
  color: #C62F68;
  font-weight: bold;
  text-align: center;
`

const Point3Feature2 = styled.div`
  ${customMedia.lessThan("medium")`
    margin: 0 auto;
    height: 60px;
    width: 130px;
    overflow: hidden;

    div:first-child {
      font-size: 1.2rem;
      margin-top: 10px;
    }

    div:nth-child(2) {
      font-size: 1.8rem;    
    }
  `}
  ${customMedia.greaterThan("medium")`
    height: 89px;
    position: absolute;
    left: 310px;
    top: 0;
    width: 173px;

    div:first-child {
      font-size: 1.4rem;
      margin-top: 20px;
    }

    div:nth-child(2) {
      font-size: 2rem;    
    }
  `}

  background-color: #fff;
  border-radius: 10px;
  color: #C62F68;
  font-weight: bold;
  text-align: center;
`

const Point3Arrow = styled.div`
  ${customMedia.lessThan("medium")`
    border-width: 12px 12px 0 12px;
    margin-top: 10px;
  `}
  ${customMedia.greaterThan("medium")`
    border-width: 15px 15px 0 15px;
    margin-top: 30px;
  `}

  width: 0;
  height: 0;
  border-style: solid;
  border-color: #0055A5 transparent transparent transparent;
  margin: 0 auto;
`

const Point3Description2 = styled.div`
  ${customMedia.lessThan("medium")`
    font-size: 1.4rem;
    margin-top: 12px;
    padding: 8px 0;
  `}
  ${customMedia.greaterThan("medium")`
    font-size: 1.8rem;
    margin: 30px auto 0;
    padding: 10px 0;
    width: 504px;
  `}

  color: #fff;
  background-color: #0055A1;
  border-radius: 10px;
  font-weight: bold;
  letter-spacing: 0.1em;
  text-align: center;
`

const Point3Description3 = styled.div`
  ${customMedia.lessThan("medium")`
    font-size: 1.4rem;
    letter-spacing: 0.04em;
    margin: 30px 0;
  `}
  ${customMedia.greaterThan("medium")`
    font-size: 1.6rem;
    margin: 35px 0 55px;
    text-align: center;
    letter-spacing: 0.14em;
    line-height: 30px;
  `}

  color: #fff;
`

const Point4Description1 = styled.div`
  ${customMedia.lessThan("medium")`
    font-size: 1.4rem;
    letter-spacing: 0.04em;
    margin-top: 1.8rem;
  `}
  ${customMedia.greaterThan("medium")`
    font-size: 1.6rem;
    margin-top: 3rem;
    letter-spacing: 0.14em;
    text-align: center;
  `}

  color: #00559F;
`

const Point4ImageContainer = styled.div`
  ${customMedia.lessThan("medium")`
    margin-top: 20px;
  `}
  ${customMedia.greaterThan("medium")`
    margin: 40px auto 0;
    width: 462px;
  `}
  
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
  justify-content: space-between;
`

const Point4ImageWrapper = styled.div`
  ${customMedia.lessThan("medium")`
  `}
  ${customMedia.greaterThan("medium")`
  `}

  width: 50%;
`

const Point4Description2 = styled.div`
  ${customMedia.lessThan("medium")`
    font-size: 1.4rem;
    letter-spacing: 0.04em;
    margin: 20px 0 30px;
  `}
  ${customMedia.greaterThan("medium")`
    font-size: 1.6rem;
    margin: 35px 0 60px;
    line-height: 30px;
    letter-spacing: 0.14em;
    text-align: center;
  `}

  color: #00559F;
`

const AskUsContainer = styled.div`
  ${customMedia.lessThan("medium")`
    padding: 0 20px;
  `}
  ${customMedia.greaterThan("medium")`
  `}

  background-color: #0055A5;
  overflow: hidden;
`

const AskUsLogoWrapper = styled.div`
  ${customMedia.lessThan("medium")`
    margin: 20px auto 0;
    width: 30px;
  `}
  ${customMedia.greaterThan("medium")`
    margin: 30px auto 0;
    width: 47px;
  `}
`

const AskUsTitle = styled.div`
  ${customMedia.lessThan("medium")`
    font-size: 1.8rem;
    letter-spacing: 0.12em;
    margin-top: 15px;
  `}
  ${customMedia.greaterThan("medium")`
    font-size: 2.8rem;
    letter-spacing: 0.2em;
    margin-top: 20px;
  `}
  
  color: #fff;
  font-weight: bold;
  text-align: center;
`

const AskUsDescription = styled.div`
  ${customMedia.lessThan("medium")`
    font-size: 1.4rem;
    letter-spacing: 0.04em;
    margin: 15px 0 30px;
  `}
  ${customMedia.greaterThan("medium")`
    font-size: 1.6rem;
    line-height: 30px;
    letter-spacing: 0.14em;
    margin: 35px 0 50px;
    text-align: center;
  `}

  color: #fff;
`

const SomeComponent = styled.div`
  ${customMedia.lessThan("medium")`
  `}
  ${customMedia.greaterThan("medium")`
  `}
`

export default IndexPage
